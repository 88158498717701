

@media only screen and (min-width: 400px) {
	.withdrawTitle {
		font-size: 40px
	}

}




@import "https://fonts.googleapis.com/css?family=Arimo:400,700";

.container-alt {
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px
}


.widget-chart li {
	width: 31.5%;
	display: inline-block;
	padding: 0
}

.widget-chart li i {
	font-size: 22px
}

.mini-stat {
	box-shadow: 1px 0 20px rgba(0, 0, 0, .05);
	padding: 20px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	margin-bottom: 30px
}

.mini-stat-icon {
	width: 60px;
	height: 60px;
	display: inline-block;
	line-height: 60px;
	text-align: center;
	font-size: 30px;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	float: left;
	margin-right: 10px
}

.mini-stat-info {
	font-size: 14px;
	padding-top: 2px
}

.mini-stat-info span {
	display: block;
	font-size: 24px
}



.imstat1{
	background-color:#00cc66 !important;
}

.imstat2{
	background-color:#ff9800 !important;
}

.imstat3{
	background-color:#1094f7 !important;
}

@media only screen and (max-width: 750px) {
	.align-self-stretch{
		max-height:300px !important;
		min-height:100px !important;
	}
}

@media only screen and (max-width: 600px) {
	.align-self-stretch{
		max-height:200px !important;
		min-height:100px !important;
	}
}

.thumb-md {
    height: 48px;
    width: 48px;
}




* > h3,h4 {
	font-family: MB;
	font-weight: 100;
}
* {
	font-family: M;
	/*font-family: 'Inter var', sans-serif;*/
}



body {
	background-color: #f1f4f8;
}

button:focus {
	outline: 0px;
}

.login-btn, .signup-btn{
	font-size: 18px;
	font-weight: 600;
}

