@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital@0;1&family=Rowdies:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Arimo:400,700);
@font-face {
    font-family: 'Burbank';
    src: url(/fonts/Burbank_Big_Regular_Bold.ttf);
}

@font-face {
    font-family: 'BurbankBold';
    src: url(/fonts/BurbankBigCondensed-Bold.otf);
}

:root {
    --color: #383838;
    --background-color: #36393f;
    --nav-background-color: #232428;
    --discord-color: #7289da;
    --discord-hover-color: #8ea1e1;
}

.swal2-input {
    font-size: 16px !Important;
}

* {
    box-sizing: border-box;
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

body {
    width: 100vw;
    height: 100%;
    max-height: 100vh;
    color: #000000;
    background: #ffffff;
    font-family: 'Open Sans', sans-serif, Helvetica, arial, sans-serif;
    font-size: 14px;
    margin: 0 auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

button {
    transition: 350ms ease-out !important;
}

button:hover {
    box-shadow: 0 16px 24px -12px rgba(0, 0, 0, 0.5);
    transform: translateY(-2px);
}

.navbar-toggler span {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: #fff;
}

.navbar-toggler span + span {
    margin-top: 4px;
    width: 18px;
}

.navbar-toggler span + span + span {
    width: 10px;
}

.header_header__3etq9 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 72px;
    padding: 0px 32px;
    background: #5591f5;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.header_nav__1ZcsJ {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.header_title__3RB8h {
    font-family: Rowdies;
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 0.55px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
}

.header_nav__1ZcsJ > ul {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.header_navLink__239DR {
    white-space: nowrap;
    font-family: Whitney, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 13px;
    letter-spacing: 0.55px;
    padding: 8px 0;
    transition: 0.2s;
}

.header_navLink__239DR:not(.active):hover {
    box-shadow: 0 4px 0px -1px white;
    animation: pulse 1s infinite;
}

.header_navLink__239DR > a {
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    font-size: 15px;
    opacity: 0.7;
    text-decoration: none;
    padding: 15px 10px;
    transition: opacity 175ms ease-out 0s;
}

.header_navLink__239DR > a:hover {
    opacity: 1;
}

.active {
    box-shadow: 0 4px 0px -1px white;
}

.active a {
    opacity: 1;
}

#bg {
    background-image: url(https://cdn.discordapp.com/attachments/498907103239471104/747012125158932530/roblox-bg.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.landing_container__18jTX {
    display: flex;
    min-height: calc(80vh - 72px);
    justify-content: center;
    opacity: 0;
    animation: loadAnimation 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s
        forwards;
    padding: 150px;
}

#bg > .con {
    min-height: calc(100vh - 72px);
    padding: 150px 0;
}

.landingPageTitle {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: left;
}

.landingPageTitle h1 {
    color: #fff;
    font-size: 9vw;
    font-family: BurbankBold;
    line-height: 0.95;
    margin: 0;
    -webkit-text-stroke: 2px #000;
    text-shadow: 0 0 8px #000;
    text-transform: uppercase;
}

.blueText {
    color: #2ec0f9 !important;
    font-size: 11vw !important;
}

.textRight {
    text-align: right;
}

.startNow {
    width: 350px;
    padding: 15px;
    font-size: 45px;
    font-family: Burbank;
    border-radius: 4px;
    text-transform: uppercase;
    background: #b56cb5;
    color: #fff;
    box-shadow: 0 0 8px 0 #000 !important;
}

.faq {
    background: #4285f4;
    color: #fff;
    padding: 50px;
    min-height: auto;
}

.noPadding {
    padding: 0;
}

.landing_content__Ozl7S {
    display: flex;
    max-width: 100%;
    align-items: center;
    flex-direction: column;
    -webkit-box-align: center;
}

.landing_heading__K9vZ3 {
    margin: auto;
    width: 100%;
}

.landing_content__Ozl7S > h1 {
    font-family: Rowdies;
    padding: 20px 0 10px 0;
    text-align: center;
    text-transform: none;
    font-size: 42px;
}

.landing_content__Ozl7S > h2 {
    font-family: Rowdies;
    text-align: center;
    text-transform: none;
    font-size: 26px;
}

.landing_content__Ozl7S > p {
    font-family: 'Open Sans';
    font-size: 19px;
    margin-top: 15px;
    margin-bottom: 18px;
    text-align: center;
    line-height: 1.8;
}

.landing_buttonsHolder__HV8uI {
    display: flex;
    margin-top: 20px;
}

.landing_buttonsHolder__HV8uI > button {
    display: flex;
    width: 300px;
    height: 60px;
    min-height: 32px;
    color: #fff;
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Open Sans', Helvetica, arial, sans-serif;
    line-height: 1;
    outline: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    align-items: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    justify-content: center;
    animation: newUpdate 3s;
    -webkit-animation: newUpdate 3s;
    animation-iteration-count: infinite;
    position: relative;
    top: 80px;
}

.landing_linkAccount__39eq8 {
    background-color: #da7272;
}

.landing_linkAccount__39eq8:hover {
    background-color: #e18e8e;
}

.p-4 {
    transition: ease all 0.3s;
}

.p-4:hover {
    box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.note {
    color: #d8ff00;
}

.footer {
    overflow: hidden;
    background-color: rgb(255 255 255);
    color: #000;
    font-weight: 900;
    opacity: 0;
    animation: loadAnimation 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s
        forwards;
}

.surveys > iframe {
    height: 100%;
    width: 100%;
    border: none;
}

.tab {
    padding: 1rem;
    padding-top: 1rem;
    width: 100%;
    height: 800px;
}

.surveys {
    height: 100%;
}

.modal {
    background: rgba(0, 0, 0, 0.3);
}

.modal-dialog.cascading-modal {
    margin-top: 0;
}

.container {
    text-align: center;
}

.container > button {
    width: 200px;
    display: inline-block;
    margin: 2px !important;
    position: relative;
}

::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}

::-webkit-scrollbar-corner {
    border: none;
    background: none;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-color: transparent;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    border-width: 3px;
    border-radius: 7px;
    background-clip: padding-box;
    border-style: solid;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    border-width: 3px;
    border-radius: 7px;
    background-clip: padding-box;
    border-style: solid;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    border-width: 3px;
    border-radius: 7px;
    background-clip: padding-box;
    border-style: solid;
}

::-webkit-scrollbar-track {
    border-width: medium;
    border-width: initial;
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    border-width: 3px;
    border-radius: 7px;
    background-clip: padding-box;
    border-style: solid;
}

@keyframes loadAnimation {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0% {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
        margin: auto;
    }
}

@media (max-width: 992px) {
    .navbar-nav > li {
        margin-top: 8px;
    }

    .header_navLink__239DR:not(.active):hover {
        animation: none;
        background-color: rgba(255, 255, 255, 0.15);
        color: #fff;
        box-shadow: none;
    }
}

@keyframes newUpdate {
    0% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(0.95);
    }
}

.landingYouTube {
    padding: 20px;
}

.socials {
    text-align: center;
    color: #fff;
    width: 650px;
    float: right;
}

.icon {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
    display: inline-block;
    width: 55px;
    height: 55px;
    line-height: 45px;
    margin: 0 10px;
    text-align: center;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.09);
    text-decoration: none;
}

.icon:before {
    content: '';
    width: 150%;
    height: 150%;
    position: absolute;
    top: 90%;
    left: -110%;
    transform: rotate(45deg);
    transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
}

.icon:hover:before {
    top: -24%;
    left: -20%;
}

.icon.facebook:before {
    background-color: #3b5998;
}

.icon.twitter:before {
    background-color: #3cf;
}

.icon.discord:before {
    background-color: #7289da;
}

.icon i {
    font-size: 46px;
    vertical-align: middle;
    transform: scale(0.8);
    transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
    line-height: 1.2;
}

.fa-facebook-square {
    color: #3b5998;
}

.fa-twitter-square {
    color: #1da1f2;
}

.fa-discord {
    color: #7289da;
}

.icon:hover i {
    color: #fff;
    transform: scale(1);
}

@media screen and (max-width: 400px) {
	.startNow {
		margin-top: 10px;
		padding: 10px;
		font-size: 25px;
		width: 250px;
	}
}

@media screen and (max-width: 1100px) {
    .socials {
        width: auto;
        float: none;
    }

    iframe {
        width: 255px;
        height: 150px;
    }

    .landingPageTitle h1 {
        font-size: 17vw;
        text-align: center;
    }

    .blueText {
        font-size: 23vw !important;
    }

    .con {
        padding: 40px 0 !important;
    }

    .startNow {
        margin-top: 10px;
        padding: 10px;
      /*  width: 250px;*/
    }

    .landingPageTitle {
        text-align: center;
    }

    .textRight {
        text-align: center;
    }

    .mainTitle {
        font-size: 34px !important;
        display: inline-block;
        line-height: 2.5 !important;
    }
}
.nav-wrapper * {
	float: left;
	margin: 0;
	padding: 0;
	font-family: M;
	opacity: 1;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	color: #c2c9cd;
	box-sizing: border-box
}

.navbar-toggler {
	padding: 20px;
	display: none;
    margin-top: 15px;
}

.nav-wrapper {
	position: relative;
	top: 0;
	left: 0;
	background: #13a065;
	width: 100%;
	z-index: 40;
	height: 90px;
	padding: 0 calc(40% - 600px)
}

.nav-logo {
	height: 90px
}

.nav-logo img {
	height: 60px
}

.nav-mobile-username {
	display: none !important;
}

@media screen and (max-width:1575px) {
	.nav-logo {
		margin-left: 25px
	}
}

@media screen and (max-width:1345px) {
	.nav-logo {
		display: none
	}
	.nav-iimg {
		display: none
	}
}

@media screen and (max-width:1285px) and (min-width:1100px) {
	.nav-translate {
		width: 100px!important
	}
}

@media screen and (max-width:1185px) {
	.nav-wrapper {
		padding: 0!important
	}
	.nav-right .right {}
}

.nav-right {
	height: 90px
}

.nav-pages {
	height: 90px;
	margin-right: 20px
}

.nav-page {
	height: 90px;
	line-height: 90px;
	position: relative;
	margin: 0 20px;
	width: 60px;
	text-align: center
}

.nav-page.mobile {
	display: none
}

.nav-page.wide {
	width: 97px
}

.nav-page::after {
	position: absolute;
	content: "";
	height: 3px;
	width: 25px;
	border-radius: 0 0 3px 3px;
	background: #fff;
	display: none;
	top: 0;
	left: 50%;
	transform: translateX(-50%)
}

.nav-page:hover,
.nav-page.active {
	color: #fff!important;
	font-family: MB
}

.nav-page:hover::after,
.nav-page.active::after {
	display: block
}

.nav-link {
	margin: 15px 0;
	height: 60px;
	width: 185px;
	color: #fff!important;
	background: #968eff;
	border-radius: 10px
	
}

.nav-link {
	width: auto;
	padding: 0 26px
}

.nav-link span {
	float: none;
	color: #fff;
	margin-right: 4px
}

.nav-toggle {
	height: 90px;
	width: 50px;
	margin-left: 20px;
	display: none
}

.nav-toggle img {
	height: 25px;
	width: 25px
}

.nav-info {
	height: 90px;
	margin-left: 30px;
	padding-top: 26px;
	width: 110px
}

.nav-ititle {
	color: #fff;
	width: 100%;
	text-align: right
}

.nav-itext {
	width: 100%;
	text-align: right
}

.nav-iimg {
	height: 90px;
	width: 50px;
	margin-left: 20px
}

.nav-iimg img {
	height: 40px;
	width: 40px
}

.nav- {}

.nav- {}

.nav- {}


@media screen and (max-width:1300px) {
	.nav-wrapper {
		padding: 0 50px
	}
}

@media screen and (min-width:1100px) {
	.navbar-toggler {
		padding: 20px;
		display: none !important;
		margin-top: 15px;
	}
	
	.logout {
		display: none;
	}
}


@media screen and (max-width:1100px) {
	.nav-toggle {
		display: flex
	}
	
	.nav-mobile-username {
		display: flex !important;
	}
	
	.nav-info {
		display: none
	}
	.nav-iimg {
		display: none
	}
	.nav-page.mobile {
		display: block
	}
	.nav-pages {
		position: absolute;
		top: 100px;
		right: 0;
		width: 100%;
		height: 100vh;
    	background: #4c78c1;
		border-radius: 4px;
		padding: 15px 0;
		display: none;
		margin: 0;
		margin-top: -15px
	}
	.navbar-toggler {
		display: block;
	}
	.nav-pages.active {
		display: block
	}
	.nav-page {
		width: 100%!important;
		line-height: 50px;
		height: 50px;
		text-align: left;
		padding-left: 30px;
		margin: 0
	}
	.nav-page::after {
		left: 0;
		transform: translate(0, -50%);
		top: 50%;
		height: 25px;
		width: 3px;
		border-radius: 0 3px 3px 0
	}
}

@media screen and (max-width:600px) {
	.nav-wrapper {
		padding: 0 20px
	}
}


@media screen and (max-width:500px) {
	.nav-logo {
		display: none
	}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
}

@media screen and (max-width:307px) {
	.nav-mobile-username {
		display: none !important;
	}
}


@font-face {
	font-family: m;
	font-weight: 300;
	font-style: normal;
	src: url(/fonts/Montserrat-Regular.ttf) format("truetype")
}

@font-face {
	font-family: mb;
	font-weight: 300;
	font-style: normal;
	src: url(/fonts/Montserrat-Bold.ttf) format("truetype")
}

@font-face {
	font-family: r;
	font-weight: 300;
	font-style: normal;
	src: url(/fonts/RussoOne-Regular.ttf) format("truetype")
}



.nav-wrapper a {
	color: #fff!important;
	text-decoration: none!important
}



*:focus {
	outline: none
}

textarea:focus,
input:focus {
	outline: none
}

input {
	background: 0 0;
	border: none
}

.body {
	width: 100vw;
	height: 100vh
}

.body-wrapper {
	width: 100%;
	height: calc(100% - 90px);
	margin-top: 90px;
	overflow-y: scroll;
	position: relative;
	transition: .4s all ease
}

.body-wrapper::-webkit-scrollbar {
	width: 0;
	height: 0
}

.blur {
	-webkit-filter: blur(4px) opacity(.1);
	filter: blur(2px) opacity(.1)
}

.c {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap
}

.c2 {
	display: flex;
	justify-content: center;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap
}

.center {
	text-align: center
}

.ch-l {
	margin-left: 70px
}

.o {
	opacity: 0
}

.h {
	display: none!important
}

.p {
	cursor: pointer
}

.r {
	transform: rotate(180deg)
}

.t,
.t>img {
	transition: .2s all ease
}

.to {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap
}

.left {
	float: left
}

.right {
	float: right
}

.nomar {
	margin: 0!important
}

.nopad {
	padding: 0!important
}

.nobor {
	border: none!important
}

.button-blue {
	transition: .2s all ease
}

.button-blue:hover {
	-webkit-filter: brightness(110%);
	        filter: brightness(110%)
}

.r36 {
	font-size: 36px;
	font-family: R
}

.r24 {
	font-size: 24px;
	font-family: R
}

.r18 {
	font-size: 18px;
	font-family: R
}

.r16 {
	font-size: 16px;
	font-family: R
}

.r14 {
	font-size: 14px;
	font-family: R
}

.r12 {
	font-size: 12px;
	font-family: R
}

.r11 {
	font-size: 11px;
	font-family: R
}

.m48 {
	font-size: 48px;
	font-family: M
}

.m48b {
	font-size: 48px;
	font-family: MB
}

.m36 {
	font-size: 36px;
	font-family: M
}

.m36b {
	font-size: 36px;
	font-family: MB
}

.m24 {
	font-size: 24px;
	font-family: M
}

.m24b {
	font-size: 24px;
	font-family: MB
}

.m18 {
	font-size: 18px;
	font-family: M
}

.m18b {
	font-size: 18px;
	font-family: MB
}

.m16 {
	font-size: 16px;
	font-family: M
}

.m16b {
	font-size: 16px;
	font-family: MB
}

.m14 {
	font-size: 14px;
	font-family: M
}

.m14b {
	font-size: 14px;
	font-family: MB
}

.m12 {
	font-size: 12px;
	font-family: M
}

.m12b {
	font-size: 12px;
	font-family: MB
}

.m11 {
	font-size: 11px;
	font-family: M
}

::-webkit-scrollbar {
	width: 0;
	height: 0
}

::-webkit-scrollbar-track {
	background: #f9fafc
}

::-webkit-scrollbar-thumb {
	background: #fff
}

::-webkit-scrollbar-thumb:hover {
	background: #fff
}

::-webkit-scrollbar-thumb:active {
	background: #fff
}

.banner {
	width: 100%;
	color: #fff;
	background: #18642a;
	height: 50px;
	z-index: 100
}

[class^=swal2] {
	float: none!important;
	float: initial!important
}

.mainTitle {
	line-height: 1.5;
    font-family: Burbank;
    text-transform: uppercase;
    float: none;
    text-align: center;
    -webkit-text-stroke: 2px #000;
    text-shadow: 0 0 8px #000;
    font-size: 65px;
    color: #fff;
}

.navbar-toggler span {
    background: white;
}

.navbar-toggler span {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
	background: #fff;
	float: none;
}

.navbar-toggler span+span {
    margin-top: 4px;
    width: 18px;
}

.navbar-toggler span+span+span {
    width: 10px;
}

.nav-pages .logout {
	color: #f9dede !important;
	text-decoration: underline !important;
}


@media only screen and (min-width: 400px) {
	.withdrawTitle {
		font-size: 40px
	}

}

.container-alt {
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px
}


.widget-chart li {
	width: 31.5%;
	display: inline-block;
	padding: 0
}

.widget-chart li i {
	font-size: 22px
}

.mini-stat {
	box-shadow: 1px 0 20px rgba(0, 0, 0, .05);
	padding: 20px;
	border-radius: 3px;
	margin-bottom: 30px
}

.mini-stat-icon {
	width: 60px;
	height: 60px;
	display: inline-block;
	line-height: 60px;
	text-align: center;
	font-size: 30px;
	border-radius: 100%;
	float: left;
	margin-right: 10px
}

.mini-stat-info {
	font-size: 14px;
	padding-top: 2px
}

.mini-stat-info span {
	display: block;
	font-size: 24px
}



.imstat1{
	background-color:#00cc66 !important;
}

.imstat2{
	background-color:#ff9800 !important;
}

.imstat3{
	background-color:#1094f7 !important;
}

@media only screen and (max-width: 750px) {
	.align-self-stretch{
		max-height:300px !important;
		min-height:100px !important;
	}
}

@media only screen and (max-width: 600px) {
	.align-self-stretch{
		max-height:200px !important;
		min-height:100px !important;
	}
}

.thumb-md {
    height: 48px;
    width: 48px;
}




* > h3,h4 {
	font-family: MB;
	font-weight: 100;
}
* {
	font-family: M;
	/*font-family: 'Inter var', sans-serif;*/
}



body {
	background-color: #f1f4f8;
}

button:focus {
	outline: 0px;
}

.login-btn, .signup-btn{
	font-size: 18px;
	font-weight: 600;
}


.referralBox {
	pointer-events: all !important;
}

#my-locker-body-offers-list a .mobile-description .easyBadge {
    width: 42px;
    height: 16px;
    padding-top: 3px !important;
    font-size: 9px !important;
	background-color: #c49bd8!important;
}


/************************
 * DON'T EDIT BELOW HERE
 ************************/



#my-locker #my-locker-body-offers #my-locker-body-offers-list a span {
    display: block;
    margin: 0 auto;
    max-width: 92%;
    overflow: visible;
    padding: 0 12px;
    white-space: normal;
}

#my-locker {
	border: 0 !important;
	margin: 0 !important;
	max-width: 100% !important;
	width: 100% !important;
	position: relative;
}

#my-locker-body-offers-list a {
	border-bottom: 1px solid #f3f3f3 !important;
	display: block;
	font-size: 0;
	margin-bottom: 0 !important;
	opacity: 1;
}

#my-locker-body-offers-list a>div {
	display: inline-block;
	vertical-align: top;
}

#my-locker-body-offers-list a .mobile-image {
	text-align: center;
	width: 65px;
}

#my-locker-body-offers-list a .mobile-image img {
	border-radius: 4px;
	display: block;
	height: 55px;
	margin: 0;
	width: 55px;
}

#my-locker-body-offers-list a .mobile-image i {
	font-size: 7px;
	margin-right: 2px;
}


#my-locker-body-offers-list a .mobile-description {
	text-align: left;
	width: calc(100% - 160px);
}

#my-locker-body-offers-list a .mobile-description span {
	font-size: 13px !important;
	font-weight: bold !important;
	margin: 0 !important;
	padding: 0 !important;
}

#my-locker-body-offers-list a .mobile-description font {
	font-size: 12px;
	font-weight: normal;
}

#my-locker-body-offers-list a .mobile-button {
	text-align: center;
	width: 80px;
}

#my-locker-body-offers-list a .mobile-button button {
    border: 0;
    border-bottom: 4px solid #ad6f6f;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    height: 48px;
    padding: 3px 0 0;
    text-transform: uppercase;
    width: 100%;
}



/* BUT PLEASE DON'T TOUCH THESE */

body.mobile {
	margin: 0 !important;
	padding: 0 !important;
}

body.editing.mobile {
	padding: 0 !important;
}

#my-locker {
	max-width: 400px !important;
	width: 400px !important;
}

body.mobile #my-locker {
	border: 0 !important;
	margin: 0 !important;
	max-width: 100% !important;
	width: 100% !important;
}

body.editing #my-locker {
	margin: 39px 0 0 380px !important;
	max-width: 400px !important;
}

#my-locker #my-locker-top {
	display: none !important;
}

.my-locker-body-text-top {
	display: none !important;
}

#my-locker #my-locker-body {
	padding: 0 !important;
	width: 100%;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list {
	margin: 0 !important;
	padding: 0 !important;
}

#custom-heading {}

#custom-heading #custom-heading-image {
	background: #7a00ff;
	font-size: 0;
}

#custom-heading #custom-heading-image img {
	display: block;
	height: auto;
	margin: 0 auto;
	padding-top: 14px;
	width: 250px;
}

#custom-heading #custom-heading-text {
	margin-bottom: 6px;
	padding: 0px 6px;
	position: relative;
}

body.mobile #custom-heading #custom-heading-text {
	margin-top: -4px;
}

@media only screen and (max-width: 460px) {
	#custom-heading #custom-heading-image img {
		width: 90%;
	}
}

#custom-heading-text {
	background: #56aef1;
	color: #ffffff;
	text-align: center
}

#custom-heading-text {
	font-size: 13px;
	font-weight: bold;
	padding-bottom: 14px !important;
	padding-top: 18px !important
}

.mobile-button button {
    background: #ff0000!important;
    color: #fff!important;
}

#my-locker-background {
	opacity: 1;
}

#my-locker {
	font-family: 'Open Sans', sans-serif !important;
}

#my-locker {
	box-shadow: 0px 1px 22px transparent;
}

#my-locker {
	border-radius: 0px;
}

#my-locker {
	max-width: 800px;
}

#my-locker #my-locker-top {
	display: block;
}

#my-locker #my-locker-top {
	background: url() top left no-repeat #969696;
}

#my-locker #my-locker-top {
	border-bottom-color: #ffffff;
}

#my-locker #my-locker-top {
	border-bottom-width: 0px;
}

#my-locker #my-locker-top {
	box-shadow: 0px 2px 5px transparent;
}

#my-locker #my-locker-top {
	padding-top: 18px;
}

#my-locker #my-locker-top {
	padding-bottom: 14px;
}

#my-locker #my-locker-top span {
	text-align: center;
}

#my-locker #my-locker-top span {
	color: #ffffff;
}

#my-locker #my-locker-top span {
	font-size: 13px;
}

#my-locker #my-locker-top span {
	font-weight: bold;
}

#my-locker #my-locker-body-human-verification button {
	background: url() top left no-repeat #fbfbfb;
}

#my-locker #my-locker-body-human-verification button:hover {
	background: url() top left no-repeat #b1b1b1;
}

#my-locker #my-locker-body-human-verification button {
	border-radius: 4px;
}

#my-locker #my-locker-body-human-verification button {
	border-color: #b9b9b9;
}

#my-locker #my-locker-body-human-verification button:hover {
	border-color: #b9b9b9;
}

#my-locker #my-locker-body-human-verification button {
	border-width: 1px;
}

#my-locker #my-locker-body-human-verification button {
	font-size: 14px;
}

#my-locker #my-locker-body-human-verification button {
	font-weight: normal;
}

#my-locker #my-locker-body-human-verification button {
	color: #9c9c9c;
}

#my-locker #my-locker-body-human-verification button:hover {
	color: #565656;
}

#my-locker #my-locker-body-human-verification button {
	padding-top: 8px;
}

#my-locker #my-locker-body-human-verification button {
	padding-bottom: 8px;
}

#my-locker #my-locker-body-human-verification {
	padding-top: 84px;
}

#my-locker #my-locker-body-human-verification {
	padding-bottom: 79px;
}

#my-locker .my-locker-body-text-top {
	text-align: center;
}

#my-locker .my-locker-body-text-top {
	color: #696969;
}

#my-locker .my-locker-body-text-top {
	font-size: 15px;
}

#my-locker .my-locker-body-text-top {
	font-weight: normal;
}

#my-locker .my-locker-body-text-top {
	text-shadow: 1px 1px 2px transparent;
}

#my-locker .my-locker-body-text-top {
	padding-top: 0px;
}

#my-locker .my-locker-body-text-top {
	padding-bottom: 0px;
}

#my-locker .my-locker-body-text-bottom {
	text-align: center;
}

#my-locker .my-locker-body-text-bottom {
	color: #959595;
}

#my-locker .my-locker-body-text-bottom {
	font-size: 13px;
}

#my-locker .my-locker-body-text-bottom {
	font-weight: normal;
}

#my-locker .my-locker-body-text-bottom {
	text-shadow: 1px 1px 2px transparent;
}

#my-locker .my-locker-body-text-bottom {
	padding-top: 1px;
}

#my-locker .my-locker-body-text-bottom {
	padding-bottom: 0px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	text-align: center;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	width: 100%;
}


#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	border-radius: 0px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	border-color: #000000;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	border-width: 0px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	text-align: center;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	font-size: 13px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	font-weight: bold;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	color: #000000;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a:hover {
	color: #000000;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	margin-top: 0px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	margin-bottom: 0px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	padding-top: 9px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	padding-bottom: 9px;
}

#my-locker #my-locker-bottom {
	display: none;
}

#my-locker #my-locker-bottom {
	background: url() top left no-repeat #ffffff;
}

#my-locker #my-locker-bottom {
	border-top-color: #ffffff;
}

#my-locker #my-locker-bottom {
	border-top-width: 1px;
}

#my-locker #my-locker-bottom {
	box-shadow: 0px -2px 5px #d1d1d1;
}

#my-locker #my-locker-bottom {
	padding-top: 13px;
}

#my-locker #my-locker-bottom {
	padding-bottom: 11px;
}

#my-locker #my-locker-bottom span {
	text-align: center;
}

#my-locker #my-locker-bottom span {
	color: #5f5f5f;
}

#my-locker #my-locker-bottom span {
	font-size: 10px;
}

#my-locker #my-locker-bottom span {
	font-weight: normal;
}

body.mobile #my-locker #my-locker-top {
	border-bottom-width: 0px;
}

body.mobile #my-locker #my-locker-top {
	padding-top: 12px;
}

body.mobile #my-locker #my-locker-top {
	padding-bottom: 14px;
}

body.mobile #my-locker #my-locker-top span {
	font-size: 12px;
}

body.mobile #my-locker #my-locker-top span {
	font-weight: normal;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	border-radius: 4px;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	border-width: 1px;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	font-size: 11px;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	font-weight: normal;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	padding-top: 8px;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	padding-bottom: 8px;
}

body.mobile #my-locker #my-locker-body-human-verification {
	padding-top: 60px;
}

body.mobile #my-locker #my-locker-body-human-verification {
	padding-bottom: 57px;
}

body.mobile #my-locker .my-locker-body-text-top {
	font-size: 13px;
}

body.mobile #my-locker .my-locker-body-text-top {
	font-weight: bold;
}

body.mobile #my-locker .my-locker-body-text-top {
	padding-top: 0px;
}

body.mobile #my-locker .my-locker-body-text-top {
	padding-bottom: 0px;
}

body.mobile #my-locker .my-locker-body-text-bottom {
	font-size: 10px;
}

body.mobile #my-locker .my-locker-body-text-bottom {
	font-weight: normal;
}

body.mobile #my-locker .my-locker-body-text-bottom {
	padding-top: 0px;
}

body.mobile #my-locker .my-locker-body-text-bottom {
	padding-bottom: 1px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	width: 100%;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	border-width: 0px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	font-size: 9px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	font-weight: normal;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	margin-top: 0px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	margin-bottom: 4px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	padding-top: 9px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	padding-bottom: 9px;
}

body.mobile #my-locker #my-locker-bottom {
	border-top-width: 1px;
}

body.mobile #my-locker #my-locker-bottom {
	padding-top: 5px;
}

body.mobile #my-locker #my-locker-bottom {
	padding-bottom: 8px;
}

body.mobile #my-locker #my-locker-bottom span {
	font-size: 9px;
}

body.mobile #my-locker #my-locker-bottom span {
	font-weight: normal;
}

body.mobile #my-locker {
	margin: 2% auto 0 !important;
	width: 95% !important;
}

body.device-phone #my-locker {
	margin: 39px 0 0 380px !important;
	width: 480px !important;
}
.insta {
	background-color: #FB4D31 !important;
	color: #fff;
}


.nli {
	position: absolute;
	z-index: 9;
	background-color: white;
	width: 100%;
	top: 50px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	text-align: center;
	opacity: 0.8;
	filter: alpha(opacity=80);
}


#sf1r {
    font-size: 11px;
}


.adBtn {
	font-size: 17px;
	padding: .5rem 2.05rem !important;
	margin: .25rem;
}

.adBtn2 {
	font-size: 17px;
	padding: .5rem 2.05rem !important;
	margin: .25rem;
	width: 47%;
}

@media (min-width: 375px) and (max-width: 400px) {
  .adBtn {
    font-size: 14px;
  }
}

@media (min-width: 260px) and (max-width: 374px) {
  .adBtn {
    font-size: 9px;
  }
}

.socialBtn {
    font-size: 12px;
}

@media (min-width: 315px) and (max-width: 400px) {
  .socialBtn {
    font-size: 8px;
  }
}


.robux-icon {
    background-image: url(https://rbx.best/img/robuxsvg.svg);
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: 0 50%;
    vertical-align: middle;
    display: inline-block;
    /* filter: invert(100%); */
    width: 28px;
    height: 28px;
    transition: background-position 0.2s, -webkit-filter 0.5s;
    transition: background-position 0.2s, filter 0.5s;
    transition: background-position 0.2s, filter 0.5s, -webkit-filter 0.5s;
}


/*
@media only screen and (max-width: 375px) {
	.adBtn {
		font-size: 13px;
	}
}

@media only screen and (max-width: 320px) {
	.adBtn {
		font-size: 9px;
	}
}
*/
.hiddeniFrame {
	filter: blur(8px);
	-webkit-filter: blur(8px);
}

.afiframe,
.afiframe2 {
	height: 720px !important;
	width: 100% !important;
}

.ofw1 {
	background: url(/assets2/offerwall/adgate.png?v=3) no-repeat scroll 0 0 #398ed1;
}

.ofw2 {
	background: url(/assets2/offerwall/ogads.png?v=3) no-repeat scroll 0 0 #232323;
}

.ofw3 {
	background: url(/assets2/offerwall/offertoro.png?v=3) no-repeat scroll 0 0 #2a303c;
}

.ofw4 {
	background: url(/assets2/offerwall/ayet.png?v=3) no-repeat scroll 0 0 #232323;
}

.ofw5 {
	background: url(/assets2/offerwall/admedia.svg?v=3) no-repeat scroll 0 0 #fff;
}

.ofw {
	font-size: large;
	background-position: center 10px;
	height: 110px;
	background-size: 200px auto;
	border: solid black 2px;
	padding-top: 65px;
	font-size: 20px;
	font-weight: bold;
}
body {
	background-color: #f1f4f8;
}

.displayNormal {
	min-height: calc(80vh - 72px);
    justify-content: center;
    opacity: 0;
    animation: loadAnimation 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s forwards;
}

.giveawayTimerHolder {
	width: 100%;
	padding: 60px calc(50% - 584px);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.giveawayTimer {
	margin-left: -.75rem;
    margin-right: -.75rem;
	margin-top: -.75rem;
	margin-bottom: -.75rem;
	text-align: center;
	font-weight: 700;
	justify-content: center;
}

.column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: .75rem;
}

.columns {
	margin-left: -.75rem;
    margin-right: -.75rem;
	margin-top: -.75rem;
	margin-bottom: -.75rem;
	justify-content: center;
	display: flex;
}

.timerBox {
    background: #c2c9cd;
    color: #000000;
    padding: 20px;
    margin-bottom: 20px;
    font-size: 30px;
    border-radius: 10%;
}

.giveawayContentHolder {
	width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 20px;
}

.giveawayContent {
    width: 538px;
    background: #fdfeff;
    box-shadow: 0 4px 25px rgba(0,0,0,.01);
    border-radius: 4px;
}

.giveawayTitle {
	width: 100%;
    height: 51px;
    line-height: 51px;
    font-weight: 700;
    color: #4285f4 !important;
    font-family: MB;
    font-size: 17px;
	text-align: center;
    text-decoration: underline;
}

.giveawayInnerContent {
	width: 100%;
    padding: 0 25px;
}

.boxItem {
	width: 50%;
    height: 54px;
	margin: 5px 0;
	float: left;
}

.boxItem > div {
	width: 100%;
    color: #000;
    font-family: mb;
    font-size: 13px;
    font-weight: 500;
	padding-right: 25px;
	float: left;
}

.itemBox {
    width: 50%;
    background: #f5f6f8;
    border-radius: 4px;
    height: 48px;
	margin: 8px 0;
	float: left;
}

.itemBox > div {
	line-height: 48px;
    height: 48px;
    float: right;
    color: #c2c9cd;
    font-weight: 700;
    font-size: 16px;
    padding-right: 15px;
}

.first {
	margin-top: 24px;
}

.floatNone {
	float: none;
}

.last {
	float: right;
    margin-top: -13px;
}

.giveawayButtonHolder {
	width: 100%;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.giveawayButton {
	height: 60px;
    line-height: 60px;
    width: 100%;
    background: #4285f4;
    border-radius: 4px;
	color: #fff;
	font-size: 14px;
	font-family: MB;
	transition: .2s all ease;
	display: flex;
    justify-content: center;
    align-items: center;
	flex-wrap: wrap;
	cursor: pointer;
}

.giveawayButton:hover {
    -webkit-filter: brightness(110%);
            filter: brightness(110%);
}

.giveawayButton.joined {
	background: #C2C9CD;
}

@media screen and (max-width: 1268px) {
	.giveawayTimerHolder {
		padding-left: 50px !important;
		padding-right: 50px !important;
	}
}

@media screen and (min-width: 769px), print {
	.giveawayTimer {
		display: flex;
	}

	.column.spacer {
		flex: none;
	    width: 33.33333%;
	}
}
