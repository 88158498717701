.nav-wrapper * {
	float: left;
	margin: 0;
	padding: 0;
	font-family: M;
	opacity: 1;
	user-select: none;
	color: #c2c9cd;
	box-sizing: border-box
}

.navbar-toggler {
	padding: 20px;
	display: none;
    margin-top: 15px;
}

.nav-wrapper {
	position: relative;
	top: 0;
	left: 0;
	background: #13a065;
	width: 100%;
	z-index: 40;
	height: 90px;
	padding: 0 calc(40% - 600px)
}

.nav-logo {
	height: 90px
}

.nav-logo img {
	height: 60px
}

.nav-mobile-username {
	display: none !important;
}

@media screen and (max-width:1575px) {
	.nav-logo {
		margin-left: 25px
	}
}

@media screen and (max-width:1345px) {
	.nav-logo {
		display: none
	}
	.nav-iimg {
		display: none
	}
}

@media screen and (max-width:1285px) and (min-width:1100px) {
	.nav-translate {
		width: 100px!important
	}
}

@media screen and (max-width:1185px) {
	.nav-wrapper {
		padding: 0!important
	}
	.nav-right .right {}
}

.nav-right {
	height: 90px
}

.nav-pages {
	height: 90px;
	margin-right: 20px
}

.nav-page {
	height: 90px;
	line-height: 90px;
	position: relative;
	margin: 0 20px;
	width: 60px;
	text-align: center
}

.nav-page.mobile {
	display: none
}

.nav-page.wide {
	width: 97px
}

.nav-page::after {
	position: absolute;
	content: "";
	height: 3px;
	width: 25px;
	border-radius: 0 0 3px 3px;
	background: #fff;
	display: none;
	top: 0;
	left: 50%;
	transform: translateX(-50%)
}

.nav-page:hover,
.nav-page.active {
	color: #fff!important;
	font-family: MB
}

.nav-page:hover::after,
.nav-page.active::after {
	display: block
}

.nav-link {
	margin: 15px 0;
	height: 60px;
	width: 185px;
	color: #fff!important;
	background: #968eff;
	border-radius: 10px
	
}

.nav-link {
	width: auto;
	padding: 0 26px
}

.nav-link span {
	float: none;
	color: #fff;
	margin-right: 4px
}

.nav-toggle {
	height: 90px;
	width: 50px;
	margin-left: 20px;
	display: none
}

.nav-toggle img {
	height: 25px;
	width: 25px
}

.nav-info {
	height: 90px;
	margin-left: 30px;
	padding-top: 26px;
	width: 110px
}

.nav-ititle {
	color: #fff;
	width: 100%;
	text-align: right
}

.nav-itext {
	width: 100%;
	text-align: right
}

.nav-iimg {
	height: 90px;
	width: 50px;
	margin-left: 20px
}

.nav-iimg img {
	height: 40px;
	width: 40px
}

.nav- {}

.nav- {}

.nav- {}


@media screen and (max-width:1300px) {
	.nav-wrapper {
		padding: 0 50px
	}
}

@media screen and (min-width:1100px) {
	.navbar-toggler {
		padding: 20px;
		display: none !important;
		margin-top: 15px;
	}
	
	.logout {
		display: none;
	}
}


@media screen and (max-width:1100px) {
	.nav-toggle {
		display: flex
	}
	
	.nav-mobile-username {
		display: flex !important;
	}
	
	.nav-info {
		display: none
	}
	.nav-iimg {
		display: none
	}
	.nav-page.mobile {
		display: block
	}
	.nav-pages {
		position: absolute;
		top: 100px;
		right: 0;
		width: 100%;
		height: 100vh;
    	background: #4c78c1;
		border-radius: 4px;
		padding: 15px 0;
		display: none;
		margin: 0;
		margin-top: -15px
	}
	.navbar-toggler {
		display: block;
	}
	.nav-pages.active {
		display: block
	}
	.nav-page {
		width: 100%!important;
		line-height: 50px;
		height: 50px;
		text-align: left;
		padding-left: 30px;
		margin: 0
	}
	.nav-page::after {
		left: 0;
		transform: translate(0, -50%);
		top: 50%;
		height: 25px;
		width: 3px;
		border-radius: 0 3px 3px 0
	}
}

@media screen and (max-width:600px) {
	.nav-wrapper {
		padding: 0 20px
	}
}


@media screen and (max-width:500px) {
	.nav-logo {
		display: none
	}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
	.nav- {}
}

@media screen and (max-width:307px) {
	.nav-mobile-username {
		display: none !important;
	}
}


@font-face {
	font-family: m;
	font-weight: 300;
	font-style: normal;
	src: url(/fonts/Montserrat-Regular.ttf) format("truetype")
}

@font-face {
	font-family: mb;
	font-weight: 300;
	font-style: normal;
	src: url(/fonts/Montserrat-Bold.ttf) format("truetype")
}

@font-face {
	font-family: r;
	font-weight: 300;
	font-style: normal;
	src: url(/fonts/RussoOne-Regular.ttf) format("truetype")
}



.nav-wrapper a {
	color: #fff!important;
	text-decoration: none!important
}



*:focus {
	outline: none
}

textarea:focus,
input:focus {
	outline: none
}

input {
	background: 0 0;
	border: none
}

.body {
	width: 100vw;
	height: 100vh
}

.body-wrapper {
	width: 100%;
	height: calc(100% - 90px);
	margin-top: 90px;
	overflow-y: scroll;
	position: relative;
	transition: .4s all ease
}

.body-wrapper::-webkit-scrollbar {
	width: 0;
	height: 0
}

.blur {
	-webkit-filter: blur(4px) opacity(.1);
	filter: blur(2px) opacity(.1)
}

.c {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap
}

.c2 {
	display: flex;
	justify-content: center;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap
}

.center {
	text-align: center
}

.ch-l {
	margin-left: 70px
}

.o {
	opacity: 0
}

.h {
	display: none!important
}

.p {
	cursor: pointer
}

.r {
	transform: rotate(180deg)
}

.t,
.t>img {
	transition: .2s all ease
}

.to {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap
}

.left {
	float: left
}

.right {
	float: right
}

.nomar {
	margin: 0!important
}

.nopad {
	padding: 0!important
}

.nobor {
	border: none!important
}

.button-blue {
	transition: .2s all ease
}

.button-blue:hover {
	filter: brightness(110%)
}

.r36 {
	font-size: 36px;
	font-family: R
}

.r24 {
	font-size: 24px;
	font-family: R
}

.r18 {
	font-size: 18px;
	font-family: R
}

.r16 {
	font-size: 16px;
	font-family: R
}

.r14 {
	font-size: 14px;
	font-family: R
}

.r12 {
	font-size: 12px;
	font-family: R
}

.r11 {
	font-size: 11px;
	font-family: R
}

.m48 {
	font-size: 48px;
	font-family: M
}

.m48b {
	font-size: 48px;
	font-family: MB
}

.m36 {
	font-size: 36px;
	font-family: M
}

.m36b {
	font-size: 36px;
	font-family: MB
}

.m24 {
	font-size: 24px;
	font-family: M
}

.m24b {
	font-size: 24px;
	font-family: MB
}

.m18 {
	font-size: 18px;
	font-family: M
}

.m18b {
	font-size: 18px;
	font-family: MB
}

.m16 {
	font-size: 16px;
	font-family: M
}

.m16b {
	font-size: 16px;
	font-family: MB
}

.m14 {
	font-size: 14px;
	font-family: M
}

.m14b {
	font-size: 14px;
	font-family: MB
}

.m12 {
	font-size: 12px;
	font-family: M
}

.m12b {
	font-size: 12px;
	font-family: MB
}

.m11 {
	font-size: 11px;
	font-family: M
}

::-webkit-scrollbar {
	width: 0;
	height: 0
}

::-webkit-scrollbar-track {
	background: #f9fafc
}

::-webkit-scrollbar-thumb {
	background: #fff
}

::-webkit-scrollbar-thumb:hover {
	background: #fff
}

::-webkit-scrollbar-thumb:active {
	background: #fff
}

.banner {
	width: 100%;
	color: #fff;
	background: #18642a;
	height: 50px;
	z-index: 100
}

[class^=swal2] {
	float: initial!important
}

.mainTitle {
	line-height: 1.5;
    font-family: Burbank;
    text-transform: uppercase;
    float: none;
    text-align: center;
    -webkit-text-stroke: 2px #000;
    text-shadow: 0 0 8px #000;
    font-size: 65px;
    color: #fff;
}

.navbar-toggler span {
    background: white;
}

.navbar-toggler span {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
	background: #fff;
	float: none;
}

.navbar-toggler span+span {
    margin-top: 4px;
    width: 18px;
}

.navbar-toggler span+span+span {
    width: 10px;
}

.nav-pages .logout {
	color: #f9dede !important;
	text-decoration: underline !important;
}