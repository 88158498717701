.insta {
	background-color: #FB4D31 !important;
	color: #fff;
}


.nli {
	position: absolute;
	z-index: 9;
	background-color: white;
	width: 100%;
	top: 50px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	text-align: center;
	opacity: 0.8;
	filter: alpha(opacity=80);
}


#sf1r {
    font-size: 11px;
}


.adBtn {
	font-size: 17px;
	padding: .5rem 2.05rem !important;
	margin: .25rem;
}

.adBtn2 {
	font-size: 17px;
	padding: .5rem 2.05rem !important;
	margin: .25rem;
	width: 47%;
}

@media (min-width: 375px) and (max-width: 400px) {
  .adBtn {
    font-size: 14px;
  }
}

@media (min-width: 260px) and (max-width: 374px) {
  .adBtn {
    font-size: 9px;
  }
}

.socialBtn {
    font-size: 12px;
}

@media (min-width: 315px) and (max-width: 400px) {
  .socialBtn {
    font-size: 8px;
  }
}


.robux-icon {
    background-image: url(https://rbx.best/img/robuxsvg.svg);
    background-repeat: no-repeat;
    background-size: auto auto;
    background-position: 0 50%;
    vertical-align: middle;
    display: inline-block;
    /* filter: invert(100%); */
    width: 28px;
    height: 28px;
    transition: background-position 0.2s, filter 0.5s;
}


/*
@media only screen and (max-width: 375px) {
	.adBtn {
		font-size: 13px;
	}
}

@media only screen and (max-width: 320px) {
	.adBtn {
		font-size: 9px;
	}
}
*/
.hiddeniFrame {
	filter: blur(8px);
	-webkit-filter: blur(8px);
}

.afiframe,
.afiframe2 {
	height: 720px !important;
	width: 100% !important;
}

.ofw1 {
	background: url(/assets2/offerwall/adgate.png?v=3) no-repeat scroll 0 0 #398ed1;
}

.ofw2 {
	background: url(/assets2/offerwall/ogads.png?v=3) no-repeat scroll 0 0 #232323;
}

.ofw3 {
	background: url(/assets2/offerwall/offertoro.png?v=3) no-repeat scroll 0 0 #2a303c;
}

.ofw4 {
	background: url(/assets2/offerwall/ayet.png?v=3) no-repeat scroll 0 0 #232323;
}

.ofw5 {
	background: url(/assets2/offerwall/admedia.svg?v=3) no-repeat scroll 0 0 #fff;
}

.ofw {
	font-size: large;
	background-position: center 10px;
	height: 110px;
	background-size: 200px auto;
	border: solid black 2px;
	padding-top: 65px;
	font-size: 20px;
	font-weight: bold;
}