
#my-locker-body-offers-list a .mobile-description .easyBadge {
    width: 42px;
    height: 16px;
    padding-top: 3px !important;
    font-size: 9px !important;
	background-color: #c49bd8!important;
}


/************************
 * DON'T EDIT BELOW HERE
 ************************/



#my-locker #my-locker-body-offers #my-locker-body-offers-list a span {
    display: block;
    margin: 0 auto;
    max-width: 92%;
    overflow: visible;
    padding: 0 12px;
    white-space: normal;
}

#my-locker {
	border: 0 !important;
	margin: 0 !important;
	max-width: 100% !important;
	width: 100% !important;
	position: relative;
}

#my-locker-body-offers-list a {
	border-bottom: 1px solid #f3f3f3 !important;
	display: block;
	font-size: 0;
	margin-bottom: 0 !important;
	opacity: 1;
}

#my-locker-body-offers-list a>div {
	display: inline-block;
	vertical-align: top;
}

#my-locker-body-offers-list a .mobile-image {
	text-align: center;
	width: 65px;
}

#my-locker-body-offers-list a .mobile-image img {
	border-radius: 4px;
	display: block;
	height: 55px;
	margin: 0;
	width: 55px;
}

#my-locker-body-offers-list a .mobile-image i {
	font-size: 7px;
	margin-right: 2px;
}


#my-locker-body-offers-list a .mobile-description {
	text-align: left;
	width: -webkit-calc(100% - 160px);
	width: -moz-calc(100% - 160px);
	width: calc(100% - 160px);
}

#my-locker-body-offers-list a .mobile-description span {
	font-size: 13px !important;
	font-weight: bold !important;
	margin: 0 !important;
	padding: 0 !important;
}

#my-locker-body-offers-list a .mobile-description font {
	font-size: 12px;
	font-weight: normal;
}

#my-locker-body-offers-list a .mobile-button {
	text-align: center;
	width: 80px;
}

#my-locker-body-offers-list a .mobile-button button {
    border: 0;
    border-bottom: 4px solid #ad6f6f;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    height: 48px;
    padding: 3px 0 0;
    text-transform: uppercase;
    width: 100%;
}



/* BUT PLEASE DON'T TOUCH THESE */

body.mobile {
	margin: 0 !important;
	padding: 0 !important;
}

body.editing.mobile {
	padding: 0 !important;
}

#my-locker {
	max-width: 400px !important;
	width: 400px !important;
}

body.mobile #my-locker {
	border: 0 !important;
	margin: 0 !important;
	max-width: 100% !important;
	width: 100% !important;
}

body.editing #my-locker {
	margin: 39px 0 0 380px !important;
	max-width: 400px !important;
}

#my-locker #my-locker-top {
	display: none !important;
}

.my-locker-body-text-top {
	display: none !important;
}

#my-locker #my-locker-body {
	padding: 0 !important;
	width: 100%;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list {
	margin: 0 !important;
	padding: 0 !important;
}

#custom-heading {}

#custom-heading #custom-heading-image {
	background: #7a00ff;
	font-size: 0;
}

#custom-heading #custom-heading-image img {
	display: block;
	height: auto;
	margin: 0 auto;
	padding-top: 14px;
	width: 250px;
}

#custom-heading #custom-heading-text {
	margin-bottom: 6px;
	padding: 0px 6px;
	position: relative;
}

body.mobile #custom-heading #custom-heading-text {
	margin-top: -4px;
}

@media only screen and (max-width: 460px) {
	#custom-heading #custom-heading-image img {
		width: 90%;
	}
}

#custom-heading-text {
	background: #56aef1;
	color: #ffffff;
	text-align: center
}

#custom-heading-text {
	font-size: 13px;
	font-weight: bold;
	padding-bottom: 14px !important;
	padding-top: 18px !important
}

.mobile-button button {
    background: #ff0000!important;
    color: #fff!important;
}

#my-locker-background {
	opacity: 1;
}

#my-locker {
	font-family: 'Open Sans', sans-serif !important;
}

#my-locker {
	box-shadow: 0px 1px 22px transparent;
}

#my-locker {
	border-radius: 0px;
}

#my-locker {
	max-width: 800px;
}

#my-locker #my-locker-top {
	display: block;
}

#my-locker #my-locker-top {
	background: url() top left no-repeat #969696;
}

#my-locker #my-locker-top {
	border-bottom-color: #ffffff;
}

#my-locker #my-locker-top {
	border-bottom-width: 0px;
}

#my-locker #my-locker-top {
	box-shadow: 0px 2px 5px transparent;
}

#my-locker #my-locker-top {
	padding-top: 18px;
}

#my-locker #my-locker-top {
	padding-bottom: 14px;
}

#my-locker #my-locker-top span {
	text-align: center;
}

#my-locker #my-locker-top span {
	color: #ffffff;
}

#my-locker #my-locker-top span {
	font-size: 13px;
}

#my-locker #my-locker-top span {
	font-weight: bold;
}

#my-locker #my-locker-body-human-verification button {
	background: url() top left no-repeat #fbfbfb;
}

#my-locker #my-locker-body-human-verification button:hover {
	background: url() top left no-repeat #b1b1b1;
}

#my-locker #my-locker-body-human-verification button {
	border-radius: 4px;
}

#my-locker #my-locker-body-human-verification button {
	border-color: #b9b9b9;
}

#my-locker #my-locker-body-human-verification button:hover {
	border-color: #b9b9b9;
}

#my-locker #my-locker-body-human-verification button {
	border-width: 1px;
}

#my-locker #my-locker-body-human-verification button {
	font-size: 14px;
}

#my-locker #my-locker-body-human-verification button {
	font-weight: normal;
}

#my-locker #my-locker-body-human-verification button {
	color: #9c9c9c;
}

#my-locker #my-locker-body-human-verification button:hover {
	color: #565656;
}

#my-locker #my-locker-body-human-verification button {
	padding-top: 8px;
}

#my-locker #my-locker-body-human-verification button {
	padding-bottom: 8px;
}

#my-locker #my-locker-body-human-verification {
	padding-top: 84px;
}

#my-locker #my-locker-body-human-verification {
	padding-bottom: 79px;
}

#my-locker .my-locker-body-text-top {
	text-align: center;
}

#my-locker .my-locker-body-text-top {
	color: #696969;
}

#my-locker .my-locker-body-text-top {
	font-size: 15px;
}

#my-locker .my-locker-body-text-top {
	font-weight: normal;
}

#my-locker .my-locker-body-text-top {
	text-shadow: 1px 1px 2px transparent;
}

#my-locker .my-locker-body-text-top {
	padding-top: 0px;
}

#my-locker .my-locker-body-text-top {
	padding-bottom: 0px;
}

#my-locker .my-locker-body-text-bottom {
	text-align: center;
}

#my-locker .my-locker-body-text-bottom {
	color: #959595;
}

#my-locker .my-locker-body-text-bottom {
	font-size: 13px;
}

#my-locker .my-locker-body-text-bottom {
	font-weight: normal;
}

#my-locker .my-locker-body-text-bottom {
	text-shadow: 1px 1px 2px transparent;
}

#my-locker .my-locker-body-text-bottom {
	padding-top: 1px;
}

#my-locker .my-locker-body-text-bottom {
	padding-bottom: 0px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	text-align: center;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	width: 100%;
}


#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	border-radius: 0px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	border-color: #000000;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	border-width: 0px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	text-align: center;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	font-size: 13px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	font-weight: bold;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	color: #000000;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a:hover {
	color: #000000;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	margin-top: 0px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	margin-bottom: 0px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	padding-top: 9px;
}

#my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	padding-bottom: 9px;
}

#my-locker #my-locker-bottom {
	display: none;
}

#my-locker #my-locker-bottom {
	background: url() top left no-repeat #ffffff;
}

#my-locker #my-locker-bottom {
	border-top-color: #ffffff;
}

#my-locker #my-locker-bottom {
	border-top-width: 1px;
}

#my-locker #my-locker-bottom {
	box-shadow: 0px -2px 5px #d1d1d1;
}

#my-locker #my-locker-bottom {
	padding-top: 13px;
}

#my-locker #my-locker-bottom {
	padding-bottom: 11px;
}

#my-locker #my-locker-bottom span {
	text-align: center;
}

#my-locker #my-locker-bottom span {
	color: #5f5f5f;
}

#my-locker #my-locker-bottom span {
	font-size: 10px;
}

#my-locker #my-locker-bottom span {
	font-weight: normal;
}

body.mobile #my-locker #my-locker-top {
	border-bottom-width: 0px;
}

body.mobile #my-locker #my-locker-top {
	padding-top: 12px;
}

body.mobile #my-locker #my-locker-top {
	padding-bottom: 14px;
}

body.mobile #my-locker #my-locker-top span {
	font-size: 12px;
}

body.mobile #my-locker #my-locker-top span {
	font-weight: normal;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	border-radius: 4px;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	border-width: 1px;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	font-size: 11px;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	font-weight: normal;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	padding-top: 8px;
}

body.mobile #my-locker #my-locker-body-human-verification button {
	padding-bottom: 8px;
}

body.mobile #my-locker #my-locker-body-human-verification {
	padding-top: 60px;
}

body.mobile #my-locker #my-locker-body-human-verification {
	padding-bottom: 57px;
}

body.mobile #my-locker .my-locker-body-text-top {
	font-size: 13px;
}

body.mobile #my-locker .my-locker-body-text-top {
	font-weight: bold;
}

body.mobile #my-locker .my-locker-body-text-top {
	padding-top: 0px;
}

body.mobile #my-locker .my-locker-body-text-top {
	padding-bottom: 0px;
}

body.mobile #my-locker .my-locker-body-text-bottom {
	font-size: 10px;
}

body.mobile #my-locker .my-locker-body-text-bottom {
	font-weight: normal;
}

body.mobile #my-locker .my-locker-body-text-bottom {
	padding-top: 0px;
}

body.mobile #my-locker .my-locker-body-text-bottom {
	padding-bottom: 1px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	width: 100%;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	border-width: 0px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	font-size: 9px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	font-weight: normal;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	margin-top: 0px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	margin-bottom: 4px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	padding-top: 9px;
}

body.mobile #my-locker #my-locker-body-offers #my-locker-body-offers-list a {
	padding-bottom: 9px;
}

body.mobile #my-locker #my-locker-bottom {
	border-top-width: 1px;
}

body.mobile #my-locker #my-locker-bottom {
	padding-top: 5px;
}

body.mobile #my-locker #my-locker-bottom {
	padding-bottom: 8px;
}

body.mobile #my-locker #my-locker-bottom span {
	font-size: 9px;
}

body.mobile #my-locker #my-locker-bottom span {
	font-weight: normal;
}

body.mobile #my-locker {
	margin: 2% auto 0 !important;
	width: 95% !important;
}

body.device-phone #my-locker {
	margin: 39px 0 0 380px !important;
	width: 480px !important;
}