body {
	background-color: #f1f4f8;
}

.displayNormal {
	min-height: calc(80vh - 72px);
    justify-content: center;
    opacity: 0;
    animation: loadAnimation 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s forwards;
}

.giveawayTimerHolder {
	width: 100%;
	padding: 60px calc(50% - 584px);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.giveawayTimer {
	margin-left: -.75rem;
    margin-right: -.75rem;
	margin-top: -.75rem;
	margin-bottom: -.75rem;
	text-align: center;
	font-weight: 700;
	justify-content: center;
}

.column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: .75rem;
}

.columns {
	margin-left: -.75rem;
    margin-right: -.75rem;
	margin-top: -.75rem;
	margin-bottom: -.75rem;
	justify-content: center;
	display: flex;
}

.timerBox {
    background: #c2c9cd;
    color: #000000;
    padding: 20px;
    margin-bottom: 20px;
    font-size: 30px;
    border-radius: 10%;
}

.giveawayContentHolder {
	width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 20px;
}

.giveawayContent {
    width: 538px;
    background: #fdfeff;
    box-shadow: 0 4px 25px rgba(0,0,0,.01);
    border-radius: 4px;
}

.giveawayTitle {
	width: 100%;
    height: 51px;
    line-height: 51px;
    font-weight: 700;
    color: #4285f4 !important;
    font-family: MB;
    font-size: 17px;
	text-align: center;
    text-decoration: underline;
}

.giveawayInnerContent {
	width: 100%;
    padding: 0 25px;
}

.boxItem {
	width: 50%;
    height: 54px;
	margin: 5px 0;
	float: left;
}

.boxItem > div {
	width: 100%;
    color: #000;
    font-family: mb;
    font-size: 13px;
    font-weight: 500;
	padding-right: 25px;
	float: left;
}

.itemBox {
    width: 50%;
    background: #f5f6f8;
    border-radius: 4px;
    height: 48px;
	margin: 8px 0;
	float: left;
}

.itemBox > div {
	line-height: 48px;
    height: 48px;
    float: right;
    color: #c2c9cd;
    font-weight: 700;
    font-size: 16px;
    padding-right: 15px;
}

.first {
	margin-top: 24px;
}

.floatNone {
	float: none;
}

.last {
	float: right;
    margin-top: -13px;
}

.giveawayButtonHolder {
	width: 100%;
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.giveawayButton {
	height: 60px;
    line-height: 60px;
    width: 100%;
    background: #4285f4;
    border-radius: 4px;
	color: #fff;
	font-size: 14px;
	font-family: MB;
	transition: .2s all ease;
	display: flex;
    justify-content: center;
    align-items: center;
	flex-wrap: wrap;
	cursor: pointer;
}

.giveawayButton:hover {
    filter: brightness(110%);
}

.giveawayButton.joined {
	background: #C2C9CD;
}

@media screen and (max-width: 1268px) {
	.giveawayTimerHolder {
		padding-left: 50px !important;
		padding-right: 50px !important;
	}
}

@media screen and (min-width: 769px), print {
	.giveawayTimer {
		display: flex;
	}

	.column.spacer {
		flex: none;
	    width: 33.33333%;
	}
}