@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital@0;1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital@0;1&family=Rowdies:wght@300&display=swap);

@font-face {
    font-family: 'Burbank';
    src: url(/fonts/Burbank_Big_Regular_Bold.ttf);
}

@font-face {
    font-family: 'BurbankBold';
    src: url(/fonts/BurbankBigCondensed-Bold.otf);
}

:root {
    --color: #383838;
    --background-color: #36393f;
    --nav-background-color: #232428;
    --discord-color: #7289da;
    --discord-hover-color: #8ea1e1;
}

.swal2-input {
    font-size: 16px !Important;
}

* {
    box-sizing: border-box;
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

body {
    width: 100vw;
    height: 100%;
    max-height: 100vh;
    color: #000000;
    background: #ffffff;
    font-family: 'Open Sans', sans-serif, Helvetica, arial, sans-serif;
    font-size: 14px;
    margin: 0 auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

button {
    transition: 350ms ease-out !important;
}

button:hover {
    box-shadow: 0 16px 24px -12px rgba(0, 0, 0, 0.5);
    transform: translateY(-2px);
}

.navbar-toggler span {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: #fff;
}

.navbar-toggler span + span {
    margin-top: 4px;
    width: 18px;
}

.navbar-toggler span + span + span {
    width: 10px;
}

.header_header__3etq9 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 72px;
    padding: 0px 32px;
    background: #5591f5;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.header_nav__1ZcsJ {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
}

.header_title__3RB8h {
    font-family: Rowdies;
    font-weight: bold;
    font-size: 23px;
    letter-spacing: 0.55px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
}

.header_nav__1ZcsJ > ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.header_navLink__239DR {
    white-space: nowrap;
    font-family: Whitney, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 13px;
    letter-spacing: 0.55px;
    padding: 8px 0;
    transition: 0.2s;
}

.header_navLink__239DR:not(.active):hover {
    box-shadow: 0 4px 0px -1px white;
    animation: pulse 1s infinite;
}

.header_navLink__239DR > a {
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    font-size: 15px;
    opacity: 0.7;
    text-decoration: none;
    padding: 15px 10px;
    transition: opacity 175ms ease-out 0s;
}

.header_navLink__239DR > a:hover {
    opacity: 1;
}

.active {
    box-shadow: 0 4px 0px -1px white;
}

.active a {
    opacity: 1;
}

#bg {
    background-image: url(https://cdn.discordapp.com/attachments/498907103239471104/747012125158932530/roblox-bg.png);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.landing_container__18jTX {
    display: flex;
    min-height: calc(80vh - 72px);
    justify-content: center;
    opacity: 0;
    animation: loadAnimation 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s
        forwards;
    padding: 150px;
}

#bg > .con {
    min-height: calc(100vh - 72px);
    padding: 150px 0;
}

.landingPageTitle {
    height: fit-content;
    text-align: left;
}

.landingPageTitle h1 {
    color: #fff;
    font-size: 9vw;
    font-family: BurbankBold;
    line-height: 0.95;
    margin: 0;
    -webkit-text-stroke: 2px #000;
    text-shadow: 0 0 8px #000;
    text-transform: uppercase;
}

.blueText {
    color: #2ec0f9 !important;
    font-size: 11vw !important;
}

.textRight {
    text-align: right;
}

.startNow {
    width: 350px;
    padding: 15px;
    font-size: 45px;
    font-family: Burbank;
    border-radius: 4px;
    text-transform: uppercase;
    background: #b56cb5;
    color: #fff;
    box-shadow: 0 0 8px 0 #000 !important;
}

.faq {
    background: #4285f4;
    color: #fff;
    padding: 50px;
    min-height: auto;
}

.noPadding {
    padding: 0;
}

.landing_content__Ozl7S {
    display: flex;
    max-width: 100%;
    align-items: center;
    flex-direction: column;
    -webkit-box-align: center;
}

.landing_heading__K9vZ3 {
    margin: auto;
    width: 100%;
}

.landing_content__Ozl7S > h1 {
    font-family: Rowdies;
    padding: 20px 0 10px 0;
    text-align: center;
    text-transform: none;
    font-size: 42px;
}

.landing_content__Ozl7S > h2 {
    font-family: Rowdies;
    text-align: center;
    text-transform: none;
    font-size: 26px;
}

.landing_content__Ozl7S > p {
    font-family: 'Open Sans';
    font-size: 19px;
    margin-top: 15px;
    margin-bottom: 18px;
    text-align: center;
    line-height: 1.8;
}

.landing_buttonsHolder__HV8uI {
    display: flex;
    margin-top: 20px;
}

.landing_buttonsHolder__HV8uI > button {
    display: flex;
    width: 300px;
    height: 60px;
    min-height: 32px;
    color: #fff;
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Open Sans', Helvetica, arial, sans-serif;
    line-height: 1;
    outline: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    align-items: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    justify-content: center;
    animation: newUpdate 3s;
    -webkit-animation: newUpdate 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    position: relative;
    top: 80px;
}

.landing_linkAccount__39eq8 {
    background-color: #da7272;
}

.landing_linkAccount__39eq8:hover {
    background-color: #e18e8e;
}

.p-4 {
    transition: ease all 0.3s;
}

.p-4:hover {
    box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

.note {
    color: #d8ff00;
}

.footer {
    overflow: hidden;
    background-color: rgb(255 255 255);
    color: #000;
    font-weight: 900;
    opacity: 0;
    animation: loadAnimation 1.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.3s
        forwards;
}

.surveys > iframe {
    height: 100%;
    width: 100%;
    border: none;
}

.tab {
    padding: 1rem;
    padding-top: 1rem;
    width: 100%;
    height: 800px;
}

.surveys {
    height: 100%;
}

.modal {
    background: rgba(0, 0, 0, 0.3);
}

.modal-dialog.cascading-modal {
    margin-top: 0;
}

.container {
    text-align: center;
}

.container > button {
    width: 200px;
    display: inline-block;
    margin: 2px !important;
    position: relative;
}

::-webkit-scrollbar {
    width: 14px;
    height: 14px;
}

::-webkit-scrollbar-corner {
    border: none;
    background: none;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-color: transparent;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    border-width: 3px;
    border-radius: 7px;
    background-clip: padding-box;
    border-style: solid;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    border-width: 3px;
    border-radius: 7px;
    background-clip: padding-box;
    border-style: solid;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    border-width: 3px;
    border-radius: 7px;
    background-clip: padding-box;
    border-style: solid;
}

::-webkit-scrollbar-track {
    border-width: initial;
    border-color: transparent;
    background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
    border-width: 3px;
    border-radius: 7px;
    background-clip: padding-box;
    border-style: solid;
}

@keyframes loadAnimation {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
        margin: auto;
    }
}

@media (max-width: 992px) {
    .navbar-nav > li {
        margin-top: 8px;
    }

    .header_navLink__239DR:not(.active):hover {
        animation: none;
        background-color: rgba(255, 255, 255, 0.15);
        color: #fff;
        box-shadow: none;
    }
}

@keyframes newUpdate {
    0% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(0.95);
    }
}

.landingYouTube {
    padding: 20px;
}

.socials {
    text-align: center;
    color: #fff;
    width: 650px;
    float: right;
}

.icon {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
    transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
    display: inline-block;
    width: 55px;
    height: 55px;
    line-height: 45px;
    margin: 0 10px;
    text-align: center;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.09);
    text-decoration: none;
}

.icon:before {
    content: '';
    width: 150%;
    height: 150%;
    position: absolute;
    top: 90%;
    left: -110%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
    transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
}

.icon:hover:before {
    top: -24%;
    left: -20%;
}

.icon.facebook:before {
    background-color: #3b5998;
}

.icon.twitter:before {
    background-color: #3cf;
}

.icon.discord:before {
    background-color: #7289da;
}

.icon i {
    font-size: 46px;
    vertical-align: middle;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
    transition: all 0.35s cubic-bezier(0.31, -0.105, 0.43, 1.59);
    line-height: 1.2;
}

.fa-facebook-square {
    color: #3b5998;
}

.fa-twitter-square {
    color: #1da1f2;
}

.fa-discord {
    color: #7289da;
}

.icon:hover i {
    color: #fff;
    -webkit-transform: scale(1);
    transform: scale(1);
}

@media screen and (max-width: 400px) {
	.startNow {
		margin-top: 10px;
		padding: 10px;
		font-size: 25px;
		width: 250px;
	}
}

@media screen and (max-width: 1100px) {
    .socials {
        width: auto;
        float: none;
    }

    iframe {
        width: 255px;
        height: 150px;
    }

    .landingPageTitle h1 {
        font-size: 17vw;
        text-align: center;
    }

    .blueText {
        font-size: 23vw !important;
    }

    .con {
        padding: 40px 0 !important;
    }

    .startNow {
        margin-top: 10px;
        padding: 10px;
      /*  width: 250px;*/
    }

    .landingPageTitle {
        text-align: center;
    }

    .textRight {
        text-align: center;
    }

    .mainTitle {
        font-size: 34px !important;
        display: inline-block;
        line-height: 2.5 !important;
    }
}